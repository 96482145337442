/* eslint-disable react/no-unescaped-entities */
import dynamic from 'next/dynamic';
import * as Sentry from '@sentry/nextjs';

const Error404 = dynamic(() => import('@components/404/Error404'));
const ErrorBoundaryFallback = dynamic(() => import('@components/sentry/ErrorBoundaryFallback'));

const Error = ({ statusCode }) => {
  if (statusCode) {
    if (statusCode.toString() === '404') {
      return <Error404 />;
    }

    return <ErrorBoundaryFallback showRestartAppButton={false} showHomeButton />;
  }

  return null;
};

Error.getInitialProps = async context => {
  const { res, err } = context;

  await Sentry.captureUnderscoreErrorException(context);

  if (res?.statusCode) {
    return {
      statusCode: res.statusCode
    };
  }

  if (err?.statusCode) {
    return {
      statusCode: err.statusCode
    };
  }

  return {
    statusCode: 404
  };
};

Error.forceGetInitialProps = true;

export default Error;
